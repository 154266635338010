import _ from 'lodash';
import "core-js/stable";
import "regenerator-runtime/runtime";


import Navi from './components/main-nav.js';
import Hero from './components/hero.js';
import Connector from './components/connector.js';

import './components/scrollrevealer.js';

import "@fortawesome/fontawesome-pro";
import "@fortawesome/fontawesome-pro/js/solid";
import "@fortawesome/fontawesome-pro/js/light";
import "@fortawesome/fontawesome-pro/js/thin";
import "@fortawesome/fontawesome-pro/js/brands.js";


(function() {

    let ready = function() {
        // Handler when the DOM is fully loaded
        console.log("DOMReady");
        console.log("Wy");
    };
      
    if ( document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll)) {
        ready();
    } else {
        document.addEventListener("DOMContentLoaded", ready);
    }

})();